import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import cookie from 'cookie';
import { IncomingMessage } from 'http';
import fetch from 'isomorphic-unfetch';
import { NextPageContext } from 'next';

/**
 * Get the user token from cookie
 */
export const getToken = (req?: IncomingMessage): string => {
    const parsedCookie = cookie.parse(req ? req.headers.cookie ?? '' : document.cookie);

    return parsedCookie.token;
};

export const createApolloClient = (
    initialState = {},
    ssr?: NextPageContext | boolean,
    userIdToken?: string
): ApolloClient<NormalizedCacheObject> => {
    const fetchOptions = {
        agent: null
    };

    const httpUri = process.env.NEXT_PUBLIC_GRAPHQL_HTTP_ENDPOINT || '';
    const wsUri = httpUri?.replace('http', 'ws') || '';

    const authHeaders: any = {};

    if (userIdToken) {
        authHeaders.authorization = `Bearer ${userIdToken}`;
    }

    const httpLink = new HttpLink({
        uri: httpUri,
        credentials: 'same-origin',
        fetch,
        fetchOptions,
        headers: { ...authHeaders }
    });

    // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
    // 'https-proxy-agent' is required here because it's a sever-side only module
    if (typeof window === 'undefined') {
        if (process.env.https_proxy) {
            fetchOptions.agent = new (require('https-proxy-agent'))(process.env.https_proxy);
            httpLink.options.fetchOptions.agent = new (require('https-proxy-agent'))(
                process.env.https_proxy
            );
        }
        httpLink.options.fetchOptions = fetchOptions;
    }

    const webSocketLink =
        typeof window !== 'undefined'
            ? new WebSocketLink({
                  uri: wsUri,
                  options: {
                      timeout: 30000,
                      reconnect: true,
                      connectionParams: async () => {
                          return {
                              headers: {
                                  ...authHeaders
                              }
                          };
                      }
                  }
              })
            : null;

    const link = webSocketLink
        ? split(
              ({ query }) => {
                  const def = getMainDefinition(query);
                  return def.kind === 'OperationDefinition' && def.operation === 'subscription';
              },
              webSocketLink,
              httpLink
          )
        : httpLink;

    return new ApolloClient({
        connectToDevTools: Boolean(ssr),
        ssrMode: Boolean(ssr),
        link: link,
        cache: new InMemoryCache().restore(initialState)
    });
};
